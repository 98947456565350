import Store from '../store/default';
import commonConstants from '../constants/commonConstants';
import comunasRm from '../constants/comunasRm';

export const initialState = Store;


export default function recipeReducer(state = initialState, action) {
  switch (action.type) {
    case 'MAP_DATA': {
      const heatMapData = {
        ...action.heatMapData[commonConstants.NUBLE_KEY],
        ...action.heatMapData[commonConstants.BIOBIO_KEY]
      }

      let mapData = {};
      Object.assign(mapData, comunasRm);
      const noDataKey = commonConstants.NO_DATA;
      if( action.hasOwnProperty('heatMapData') ) {
        let comuna;
        for(var comunaId in mapData){
          if(comunaId === 'week')
            continue;
          comuna = mapData[comunaId];
          comuna.heatMapId = heatMapData[comuna.id];
          if(!comuna.heatMapId) {
            comuna.heatMapId = noDataKey;
          }
          comuna.heatMapClassName = commonConstants.HEAT_MAP_MAPPING[comuna.heatMapId].className;
          comuna.heatMapName = commonConstants.HEAT_MAP_MAPPING[comuna.heatMapId].name;
        }
        mapData.week = heatMapData.week;
        mapData.get = (comunaId) => {
          return mapData[comunaId] ? mapData[comunaId] : {
            order: "-1",
            id: noDataKey,
            name: comunaId ? comunaId.replace(/_/g, ' ') : '',
            heatMapClassName: commonConstants.HEAT_MAP_MAPPING[noDataKey].className,
            heatMapName: commonConstants.HEAT_MAP_MAPPING[noDataKey].name,
          };
        }
      }
      return {
        ...state,
        mapData,
      };
    }


    default:
      return state;
  }
}
