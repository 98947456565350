import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import logoAirData from '../../images/logos/logo-airdata.png';
import logoAria from '../../images/logos/logo-aria.png';
import logoCams from '../../images/logos/logo-cams.png';
import logoFundamas from '../../images/logos/logo-fundamas.png';
import logoZeke from '../../images/logos/logo-zeke.png';
import logoCopernicus from '../../images/logos/logo-copernicus.png';
import logoComiteFomentoBiobio from '../../images/logos/logo-comite-fomento-biobio.png';
import logoSeremiSaludBiobio from '../../images/logos/logo-seremi-salud-biobio.png';
import logoCorfo from '../../images/logos/logo-corfo.png';
import logoUdec from '../../images/logos/logo-udec.png';

import logoEcmwf from '../../images/logos/logo-ecmwf.png';
import logoEuropean from '../../images/logos/logo-european.png';
import Config from "../../constants/config";



const styles = theme => ({

});

const AboutSection = (props) => {
  const { classes } = props;
  return (
    <div className="section">
      <h1>Quienes somos</h1>
      <Grid container spacing={16}>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={16} direction="row" alignItems="center" justify="center">
            <Grid item xs={12}>
              <Paper className="paper" style={{height: '100%'}}>
                <h4>Auspiciador</h4>
                <hr/>
                <Grid
                  container
                  spacing={0}
                  direction="row"
                  alignItems="center"
                  justify="center"
                >
                  <Grid item>

                    <Grid container
                          spacing={40}
                          direction="row"
                          alignItems="center"
                          justify="center">
                      <Grid item xs={12} sm={6} style={{textAlign: 'center'}}>
                        <a href="http://www.fomentobiobio.cl/" target='_blank' rel="noopener noreferrer" style={{display: 'inline-block', marginBottom: '20px'}}>
                          <img width={110} src={logoComiteFomentoBiobio} alt="Copernicus"/>
                        </a>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{textAlign: 'center'}}>
                        <a href="https://corfo.cl/" target='_blank' rel="noopener noreferrer" style={{display: 'inline-block', marginBottom: '20px'}}>
                          <img width={110} src={logoCorfo} alt="logoCams"/>
                        </a>
                      </Grid>
                      {/*<Grid item xs={12} sm={6} style={{textAlign: 'center'}}>*/}
                      {/*  <a href="https://www.ecmwf.int/" target='_blank' rel="noopener noreferrer" style={{display: 'inline-block', marginBottom: '20px'}}>*/}
                      {/*    <img width={150} src={logoEcmwf} alt="logoEcmwf"/>*/}
                      {/*  </a>*/}
                      {/*</Grid>*/}
                      {/*<Grid item xs={12} sm={6} style={{textAlign: 'center'}}>*/}
                      {/*  <a href="https://ec.europa.eu/commission/" target='_blank' rel="noopener noreferrer" style={{display: 'inline-block', marginBottom: '20px'}}>*/}
                      {/*    <img width={150} src={logoEuropean} alt="logoEuropean"/>*/}
                      {/*  </a>*/}
                      {/*</Grid>*/}
                    </Grid>
                  </Grid>
                </Grid>

              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className="paper" style={{height: '100%'}}>
                <h4>Equipo {Config.appName}</h4>
                <hr/>

                <Grid className=""
                      container
                      spacing={40}
                      direction="row"
                      alignItems="center"
                      justify="center"
                      style={{marginTop: '20px'}}
                >
                  <Grid item>
                    <a href="http://udec.cl/" target='_blank' rel="noopener noreferrer">
                      <img width={130} src={logoUdec} alt="AirData" />
                    </a>
                  </Grid>
                  <Grid item>
                    <a href="http://www.aria.fr" target='_blank' rel="noopener noreferrer">
                      <img width={110} src={logoAria} alt="logoFundamas"/>
                    </a>
                  </Grid>
                  <Grid item>
                    <a href="http://www.seremidesaludbiobio.cl/" target='_blank' rel="noopener noreferrer">

                      <img width={110} src={logoSeremiSaludBiobio} alt="logoZeke"/>
                    </a>
                  </Grid>
                </Grid>
                <br/>

                <Grid
                  container
                  spacing={0}
                  direction="row"
                  alignItems="center"
                  justify="center"
                >
                  <Grid className="" item>
                    <a href="http://www.airdata.cl" target='_blank' rel="noopener noreferrer" style={{marginLeft: '30px'}}>
                      <img width={110} src={logoAirData} alt="logoAria" />
                    </a>
                  </Grid>
                </Grid>


              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Paper className="paper justifyText" style={{height: '100%'}}>
            <p>
              Somos un equipo multidisciplinarios internacional compuesto por ingenieros, economistas, profesionales del área de la salud y analistas de datos.
            </p>
            <p>
              El proyecto Respira Biobío es un sistema operacional de alertas tempranas, que permite pronosticar consultas de urgencias por enfermedades respiratorias en la red asistencial de salud de las principales zonas urbanas de las regiones del Biobío y Ñuble. El pronóstico genera mapas de riesgo que con el fin de informar en forma anticipada sobre posibles aumentos significativos en las consultas de urgencia en los centros de atención primaria y secundaria de la Red de Salud durante los meses de invierno.
            </p>
            <p>
              Esta iniciativa es el resultado de un proyecto de Innovación de Interés Público ID Nº 18IIP-93627 financiado por CORFO con el patrocinio de la Seremi de Salud de la Región del Biobío.
            </p>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(AboutSection);
