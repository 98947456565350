import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Modal from '../../../../node_modules/@material-ui/core/Modal/Modal';
import Typography from '../../../../node_modules/@material-ui/core/Typography/Typography';
import LoginComponent from './Login';
import LoginContainer from '../../../containers/Login';


const styles = theme => ({
  paper: {
    position: 'absolute',
    width: '70%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
});

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

class ChartBox extends React.Component {

  static propTypes = {
    loggedIn: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    loggedIn: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, loggedIn } = this.props;

    return (
      <React.Fragment>
      { !loggedIn &&
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          // onClose={this.handleClose}
          // disableEnforceFocus
        >
          <div style={getModalStyle()} className={classes.paper}>
            <LoginContainer {...this.props} Layout={LoginComponent}/>
          </div>
        </Modal>
      }
      </React.Fragment>
    );
  }

}

export default withStyles(styles)(ChartBox);
