import React from 'react';
import PropTypes from 'prop-types';

import Loading from '../UI/Loading';
import Error from '../UI/Error';

import  {ComposedChart, Line, Area, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label} from 'recharts';
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';
import commonConstants from '../../../constants/commonConstants';
import ReferenceDot from 'recharts/es6/cartesian/ReferenceDot';
import Grid from '../../../../node_modules/@material-ui/core/Grid/Grid';

import TooltipUI from '../../../../node_modules/@material-ui/core/Tooltip/Tooltip';
import BussinessTooltipText from '../UI/BussinessTooltipText';
import CloseIcon from '../../../../node_modules/@material-ui/icons/Close';
import IconButton from '../../../../node_modules/@material-ui/core/IconButton/IconButton';
import Dialog from '../../../../node_modules/@material-ui/core/Dialog/Dialog';
import Modal from '../../../../node_modules/@material-ui/core/Modal/Modal';
import Paper from '../../../../node_modules/@material-ui/core/Paper/Paper';
import Button from '../../../../node_modules/@material-ui/core/Button/Button';
import DialogContent from '../../../../node_modules/@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '../../../../node_modules/@material-ui/core/DialogContentText/DialogContentText';
import DialogTitle from '../../../../node_modules/@material-ui/core/DialogTitle/DialogTitle';
import Config from "../../../constants/config";


const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {

  return (
    <text x={cx} y={cy} fill="blue">
      {'hola ctm'}
    </text>
  );
};

const AxisLabel = ({ axisType, x, y, width, height, stroke, children }) => {
  const isVert = axisType === 'yAxis';
  const cx = isVert ? x : x + (width / 2);
  const cy = isVert ? (height / 2) + y : y + height + 10;
  const rot = isVert ? `270 ${cx} ${cy}` : 0;
  return (
    <text x={cx} y={cy} transform={`rotate(${rot})`} textAnchor="middle" stroke={stroke}>
      {children}
    </text>
  );
};


class Chart extends React.Component {

  static propTypes = {
    error: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    chartData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    filters: PropTypes.shape(),
    todayRedCirclePoints: PropTypes.shape(),
    dataMapping: PropTypes.shape()
  };

  static defaultProps = {
    error: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      active: "",
      tooltipActive: false,
      chartContainerHeight: 400,
      chartDialogOpen: false,
      dialogContent: "cargando...",
    };

    // this.chartLabelsLegendRef = React.createRef();
    this.customTooltipOnLine = this.customTooltipOnLine.bind(this);
    this.resizeChart = this.resizeChart.bind(this);
    // this.handleChartDialogOpen = this.handleChartDialogOpen.bind(this);
    this.activateLineTooltip = this.activateLineTooltip.bind(this);
    this.desactivateLineTooltip = this.desactivateLineTooltip.bind(this);
    this.genericCustomTooltip = this.genericCustomTooltip.bind(this);
    this.activateTooltipMobile = this.activateTooltipMobile.bind(this);



    // this.handleChartDialogClose = this.handleChartDialogClose.bind(this);


    // this.handleChange = this.handleChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleClick = (e) => {
    const dataKey = e.target.innerText;
    this.setState({ active: dataKey })
  };

  renderCusomizedLegend = (props) => {
    const { payload } = props;
    const { filters } = this.props;

    const getLineSvg = (color) => (
      <svg
        className= "recharts-surface"
        width={14}
        height={14}
        style= {{
          display: "inline-block",
          verticalAlign: "middle",
          marginRight: 4,
        }}
        viewBox= "0 0 32 32"
        version= "1.1"
      >
        <path
          strokeWidth={4}
          fill="none"
          stroke={color}
          d="M0,16h10.666666666666666 A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16 H32M21.333333333333332,16 A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"
          className="recharts-legend-icon"
        />
      </svg>
      );

    const getRectSvg = (color) => (
      <svg className="recharts-surface" width={14} height={14} viewBox="0 0 32 32" version="1.1"
           style={{display: 'inline-block', verticalAlign: 'middle', marginRight: '4px'}}>
        <path stroke="none" fill={color} d="M0,4h32v24h-32z" className="recharts-legend-icon"/>
      </svg>
    );

    const getIcon = (type, color) => {
      return type==='line' ? getLineSvg(color) : getRectSvg(color);
    };

    const printTotAndForecast =  ( filters.yearComparatorName.length === 0 ||
      (filters.yearComparatorName.length !== 0 && filters.yearComparatorName.includes((new Date().getFullYear().toString()))));

    const currentYearLabel = filters.yearComparatorName.length !== 0 ?
      (<Grid item>{ new Date().getFullYear() }: </Grid>) : "";

    const totAndForecastLabels = (
      payload.map((entry) => {
        const { dataKey, type, color, value } = entry;
        return dataKey === 'tot_resp' || dataKey === 'forecastValue' ? (
          <Grid key={dataKey} item>
            <TooltipUI title={<BussinessTooltipText messageType={commonConstants.CHART_TYPES.BY_INFECCIONES_RESPIRATORIAS}/>}>
              <div>{getIcon(type, color)} {value}</div>
            </TooltipUI>
          </Grid>
        ) : "";
      })
    );

    const gridDirection =  window.innerWidth > 700 ? "row" : "column";
    const gridSpacing =  window.innerWidth > 700 ? 24 : 0;
    return (
      <div id="chartLabelsLegend">
        {/*<Paper className="paper">*/}
          <Grid
            container
            direction={gridDirection}
            justify="center"
            alignItems="center"
            spacing={gridSpacing}
            style={{marginTop: '20px'}}
          >
            {printTotAndForecast &&
              <React.Fragment>
                {currentYearLabel}
                {totAndForecastLabels}
              </React.Fragment>
            }
          </Grid><br/>

          <Grid
            container
            direction={gridDirection}
            justify="center"
            alignItems="center"
            spacing={gridSpacing}
          >
            {
              payload.map((entry) => {
                const { dataKey, type, color, value } = entry;
                return dataKey!=='tot_resp' && dataKey!=='forecastValue' ? (
                  <Grid key={dataKey} item>{getIcon(type, color)} {value}</Grid>
                ) : "";
              })
            }
          </Grid>
        {/*</Paper>*/}
      </div>

    )
  };

  customizedLabel = (props) => {
    const {
      fill, value, textAnchor,
      fontSize, viewBox, dy, dx,
    } = props;
    const x = viewBox.width + viewBox.x + 20;
    const y = viewBox.y - 6;
    return (
      <text
        x={x} y={y}
        dy={dy}
        dx={dx}
        fill={fill}
        fontSize={fontSize || 10}
        textAnchor={textAnchor}>
        {value}
      </text>
    )
  };

  activateLineTooltip = (event) => {
    if(window.innerWidth > 700){
      this.setState({tooltipActive: true});
    }else{
      const dialogContent = this.mobileCustomTooltipContent(event);
      this.setState({chartDialogOpen: true, dialogContent: dialogContent});
    }
  };

  activateTooltipMobile(event){
    if(event===null) return;
    const dialogContent = this.genericCustomTooltip(event.activePayload);
    this.setState({chartDialogOpen: true, dialogContent: dialogContent});
  };

  desactivateLineTooltip = (e) => {
    if (window.innerWidth > 700){
      this.setState({tooltipActive: false});
    }
  };

  // chartClick = (e) => {
  //
  // };

  // handleChartDialogOpen = (e) => {
  //   if(window.innerWidth <= 700){
  //     this.setState({chartDialogOpen: true});
  //   }
  // };

  handleChartDialogClose = (e) => {
    // if (window.innerWidth <= 700){
      this.setState({chartDialogOpen: false});
    // }
  };

  mobileCustomTooltipContent(e) {
    let payload = e.payload;
    if(payload === null || payload === undefined){
      return;
    }

    const filters = this.props.filters;
    const dataMapping = this.props.dataMapping;

    let topLabelDataKey = payload.hasOwnProperty("forecastValue") ? "forecastValue" : null;
    topLabelDataKey = payload.hasOwnProperty("tot_resp") ? "tot_resp" : topLabelDataKey ;

    console.log('payload: ', payload);
    console.log('mobileCustomTooltipContent() topLabelDataKey: ', topLabelDataKey);
    let topLabel;
    if(topLabelDataKey) {
      let topLabelName = commonConstants.chartTooltipPayloadMapping[topLabelDataKey].name;
      const topLabelColor = commonConstants.chartTooltipPayloadMapping[topLabelDataKey].color;

      if(topLabelDataKey === "tot_resp"){
        topLabelName += filters.chartType === commonConstants.CHART_TYPES.BY_INFECCIONES_RESPIRATORIAS ? "ISP" : "DEIS";
      }
      topLabel = (
        <li><strong key={topLabelDataKey} style={{ color: topLabelColor }}>{topLabelName}: {payload[topLabelDataKey]}</strong>
        </li> );
    }else{
      topLabel = '';
    }

    const isByYear = filters.yearComparatorName.length > 0;
    if(isByYear){
      const newPayload = {}
      filters.yearComparatorName.forEach((year, index) => {
        newPayload[year] = {
          value: payload[year],
          color: commonConstants.getYearColor(index)
        };
      });
      payload = newPayload;
    }

    const commonLabels = Object.keys(payload).map(function(key, index) {
      const dataKey = key;
      if(dataKey !== "tot_resp" && dataKey !== "forecastValue" && dataKey !== "week" && dataKey !== "forecast") {
        let payloadMappingItem = null;
        if(filters.chartType === commonConstants.CHART_TYPES.BY_INFECCIONES_RESPIRATORIAS) {
          if(filters.infeccionesDataCategory === commonConstants.INFECCIONES_DATA_CATEGORY.POR_CUADRO_RESPIRATORIO){
            payloadMappingItem = dataMapping.diseases[dataKey];
          }else if (filters.infeccionesDataCategory === commonConstants.INFECCIONES_DATA_CATEGORY.POR_GRUPO_ETARIO){
            payloadMappingItem = dataMapping.ages[dataKey];
          }
        } else if (filters.chartType === commonConstants.CHART_TYPES.BY_VIRUS_RESPIRATORIOS){
          payloadMappingItem = dataMapping.viruses[dataKey];
        }
        let name, color;
        // if(dataKey === 'otros') {
        //   return;
        //   name = commonConstants.chartTooltipPayloadMapping[dataKey].name;
        //   color = commonConstants.chartTooltipPayloadMapping[dataKey].color;
        // } else
        if(isByYear){
          name = dataKey;
          color = payload[key].color;
        }else{
          name = payloadMappingItem ? payloadMappingItem.name : key;
          color = payloadMappingItem ? payloadMappingItem.color : "black";
        }
        // const color = commonConstants.chartTooltipPayloadMapping[dataKey].color;
        const value = isByYear ? payload[key].value : payload[key];
        if (value !== undefined && value!== null) {
          return <li key={dataKey} style={{ color: color }}>{name}: {value}</li>;
        }
      }
    });

    return(
      <div style={{backgroundColor: 'white', padding: '20px', borderStyle: 'solid', borderWidth: 'medium'}}>
        {/*<IconButton onClick={this.desactivateLineTooltip} style={{position: 'relative', top: '0px', right: '0px'}} aria-label="Cerrar">*/}
          {/*<CloseIcon />*/}
        {/*</IconButton>*/}
        <h5>Semana Epidemiológica {e.week}</h5>
        <p style={{marginTop: '-10px'}}>
          {commonConstants.getDatesFromWeek(e.week)}
        </p>
        <ul>
          {topLabel}
        </ul>
        <ul>
          {commonLabels}
        </ul>
      </div>
    );
  }

  genericCustomTooltip(payload) {
    if(payload === null || payload === undefined){
      return;
    }
    const totRespArray = payload.filter(p => p.dataKey === "tot_resp" && p.value!==undefined);
    let finalArray = payload.filter(p => p.dataKey === "forecastValue" && p.value!==undefined);

    if ( totRespArray.length > 0 ){
      finalArray = totRespArray;
    }
    let topLabel = "";
    if(finalArray!== undefined && finalArray.length >= 0 && finalArray[0] !== undefined){
      topLabel = (
        <li><strong key={finalArray[0].dataKey}
                    style={{ color: finalArray[0].color }}>
          {finalArray[0].name}: {finalArray[0].value}</strong>
        </li> );
    }

    const commonLabels = payload.map((entry) => {
      const { dataKey, name, value, stroke, fill } = entry;
      let color = fill === "#fff" ? stroke : fill;
      if(dataKey !== "tot_resp" && dataKey !== "forecastValue" && value!==undefined) {
        return <li key={dataKey} style={{ color: color }}>{name}: {value}</li>;
      }
    });
    return (
      <div style={{backgroundColor: 'white', padding: '20px', borderStyle: 'solid', borderWidth: 'medium'}}>
        {/*<IconButton onClick={this.desactivateLineTooltip} style={{position: 'relative', top: '0px', right: '0px'}} aria-label="Cerrar">*/}
        {/*<CloseIcon />*/}
        {/*</IconButton>*/}
        <h5>Semana Epidemiológica {payload[0].payload.week}</h5>
        <p style={{marginTop: '-10px'}}>
          {commonConstants.getDatesFromWeek(payload[0].payload.week)}
        </p>
        <ul>
          {topLabel}
        </ul>
        <ul>
          {commonLabels}
        </ul>
      </div>
    );
  }

  customTooltipOnLine = (e) => {
    // if (!e.payload || e.payload==null) return;
    if (!e.payload || e.payload==null || e.payload.length===0 || !this.state.tooltipActive){
      return null;
    }
    return this.genericCustomTooltip(e.payload);

    // if (!Array.isArray(e.payload)){
    //   return this.mobileCustomTooltipContent(e);
    // }


  };


  componentDidMount() {
    window.addEventListener("resize", this.resizeChart.bind(this));
    this.resizeChart();
  };

  resizeChart(){
    const chartLabelsLegend = document.getElementById("chartLabelsLegend");
    if (chartLabelsLegend !== null && chartLabelsLegend !== undefined) {
      const legendHeight = chartLabelsLegend.offsetHeight;
      const containerHeight = legendHeight + (window.innerWidth>700 ? 320 : 260);//(window.innerHeight/3);
      this.setState({ chartContainerHeight: containerHeight });
    }else{
      setTimeout(this.resizeChart, 100);
    }
  }


  render() {
    const {loading, error, chartData, filters, todayRedCirclePoints} = this.props;
    const {tooltipActive, chartContainerHeight, chartDialogOpen} = this.state;
    if (loading) return <Loading/>;
    if (error) return <Error content={error}/>;

    let bars;
    let yearLines;
    let historicoLabelName = 'Datos ISP';
    const yAxisLabelText = filters.chartType === commonConstants.CHART_TYPES.BY_INFECCIONES_RESPIRATORIAS ? "Nº consultas" : "Nº casos";

    if( filters.chartType === commonConstants.CHART_TYPES.BY_INFECCIONES_RESPIRATORIAS ) {
      historicoLabelName = "Datos DEIS";
      if(filters.yearComparatorName.length === 0){
        if( filters.infeccionesDataCategory === commonConstants.INFECCIONES_DATA_CATEGORY.POR_CUADRO_RESPIRATORIO ){
          bars = Object.entries(this.props.dataMapping.diseases).map(
            ([itemId, itemValue], i) =>
              <Bar onClick={this.activateLineTooltip}
                   onMouseOver={this.activateLineTooltip}
                   onMouseOut={this.desactivateLineTooltip}
                   maxBarSize={70} key={itemId} dataKey={itemId} name={itemValue.name} stackId="a" fill={itemValue.color}/>
          )
          // const otrosBar = <Bar onMouseUp={this.activateLineTooltip}
          //               onMouseOver={this.activateLineTooltip}
          //               onMouseLeave={this.desactivateLineTooltip}
          //               maxBarSize={70} key={5} dataKey='otros'
          //                       name={commonConstants.chartTooltipPayloadMapping.otros.name}
          //                       stackId="a"
          //                       fill={commonConstants.chartTooltipPayloadMapping.otros.color}/>;
          // bars.push(otrosBar);
        }
        if( filters.infeccionesDataCategory === commonConstants.INFECCIONES_DATA_CATEGORY.POR_GRUPO_ETARIO ){
          const ageEntries = Object.entries(this.props.dataMapping.ages);
          bars = ageEntries.map(
            ([itemId, itemValue], i) => {
              if(!itemValue) return '';
              return <Bar onClick={this.activateLineTooltip}
                          onMouseOver={this.activateLineTooltip}
                          onMouseOut={this.desactivateLineTooltip}
                          maxBarSize={70} key={itemId} dataKey={itemId} name={itemValue.name} stackId="a" fill={itemValue.color}/>
            }
          )

          // bars = [
          //   <Bar onMouseUp={this.activateLineTooltip}
          //        onMouseOver={this.activateLineTooltip}
          //        onMouseLeave={this.desactivateLineTooltip}
          //        maxBarSize={70} key={1} dataKey="1" name="<1 año" stackId="a" fill="#82ca9d"/>,
          //   <Bar onMouseUp={this.activateLineTooltip}
          //        onMouseOver={this.activateLineTooltip}
          //        onMouseLeave={this.desactivateLineTooltip}
          //        maxBarSize={70} key={2} dataKey='2' name="1-4 años" stackId="a" fill='#413ea0'/>,
          //   <Bar onMouseUp={this.activateLineTooltip}
          //        onMouseOver={this.activateLineTooltip}
          //        onMouseLeave={this.desactivateLineTooltip}
          //        maxBarSize={70} key={3} dataKey="3" name="5-14 años" stackId="a" fill="#A143A7"/>,
          //   <Bar onMouseUp={this.activateLineTooltip}
          //        onMouseOver={this.activateLineTooltip}
          //        onMouseLeave={this.desactivateLineTooltip}
          //        maxBarSize={70} key={4} dataKey='4' name="15-64 años" stackId="a" fill='orange'/>,
          //   <Bar onMouseUp={this.activateLineTooltip}
          //        onMouseOver={this.activateLineTooltip}
          //        onMouseLeave={this.desactivateLineTooltip}
          //        maxBarSize={70} key={5} dataKey='5' name=">65 años" stackId="a" fill='gray'/>,
          // ];
        }
      }
    }else if( filters.chartType === commonConstants.CHART_TYPES.BY_VIRUS_RESPIRATORIOS &&
              filters.virus === commonConstants.VIRUS.TOT_RESP && filters.yearComparatorName.length === 0) {

      bars = Object.entries(this.props.dataMapping.viruses).map(
        ([virusId, virus], i) =>
          <Bar onClick={this.activateLineTooltip}
                onMouseOver={this.activateLineTooltip}
                onMouseOut={this.desactivateLineTooltip}
                maxBarSize={70} key={virusId} dataKey={virusId} name={virus.name} stackId="a" fill={virus.color}/>
      )

      // bars = [
      //   <Bar onClick={this.activateLineTooltip}
      //        onMouseOver={this.activateLineTooltip}
      //        onMouseOut={this.desactivateLineTooltip}
      //        maxBarSize={70} key={3} dataKey={commonConstants.VIRUS.INF_A} name="Influenza A" stackId="a" fill="#A143A7"/>,
      //   <Bar onClick={this.chartClick}
      //        onMouseOver={this.activateLineTooltip}
      //        onMouseOut={this.desactivateLineTooltip}
      //        maxBarSize={70} key={1} dataKey={commonConstants.VIRUS.VRS} name="VRS" stackId="a" fill="#82ca9d"/>,
      //   <Bar onClick={this.activateLineTooltip}
      //        onMouseOver={this.activateLineTooltip}
      //        onMouseOut={this.desactivateLineTooltip}
      //        maxBarSize={70} key={2} dataKey={commonConstants.VIRUS.AD} name="Adenovirus" stackId="a" fill='#413ea0'/>,
      //   <Bar onClick={this.activateLineTooltip}
      //        onMouseOver={this.activateLineTooltip}
      //        onMouseOut={this.desactivateLineTooltip}
      //        maxBarSize={70} key={4} dataKey={commonConstants.VIRUS.INF_B} name="Influenza B" stackId="a" fill='orange'/>,
      //   <Bar onClick={this.activateLineTooltip}
      //        onMouseOver={this.activateLineTooltip}
      //        onMouseOut={this.desactivateLineTooltip}
      //        maxBarSize={70} key={5} dataKey={commonConstants.VIRUS.PARA} name="Parainfluenza" stackId="a" fill='gray'/>,
      //   <Bar onClick={this.activateLineTooltip}
      //        onMouseOver={this.activateLineTooltip}
      //        onMouseOut={this.desactivateLineTooltip}
      //        maxBarSize={70} key={6} dataKey={commonConstants.VIRUS.META} name="Metapneumovirus" stackId="a" fill='#983232'/>,
      // ];
    }
    if ( filters.yearComparatorName.length > 0 ) { //
      yearLines = filters.yearComparatorName.map((year, index) => {
        if(year !== new Date().getFullYear().toString()){
          return <Line
                        onMouseUp={this.activateLineTooltip}
                        onMouseOver={this.activateLineTooltip}
                       onMouseLeave={this.desactivateLineTooltip}
                       activeDot={{
                         onMouseOver: this.activateLineTooltip,
                         onMouseLeave: this.desactivateLineTooltip}}
                       strokeWidth={3}
                       key={year} type='monotone' dataKey={year} name={year} stroke={commonConstants.getYearColor(index)}/>
        }
      });
    }

    if(!chartData || !chartData.length || chartData.length===0){
      return (
        <h4 style={{textAlign:"center", margin: 5}}>No se encontraron datos con los filtros seleccionados.</h4>
      );
    }

    return (
      <React.Fragment>
        <ResponsiveContainer width="100%" height={chartContainerHeight}>
          <ComposedChart data={chartData}
                         margin={{ top: 35, bottom: 20, right: 10, left:25 }}
                         onClick={this.activateTooltipMobile}>
            <CartesianGrid stroke='#f5f5f5'/>
            <XAxis dataKey="week" label={{value: "Semanas Epidemiológicas", position: 'bottom'}}/>
            {/*<YAxis label={{value: "que sucede bro!", position: 'left', angle: 90}}/>*/}

            <YAxis>
              <Label angle={-90} value={yAxisLabelText} position='left' style={{textAnchor: 'middle'}} offset={18}/>
            </YAxis>

            <Tooltip
              wrapperStyle={{
                visibility: this.state.tooltipActive ? 'visible' : 'hidden',
              }}
              active={this.state.tooltipActive}
              content={this.customTooltipOnLine}
              labelFormatter={function(week) {
                return(
                  <React.Fragment>
                    <p>Semana Epidemiológica {week}</p>
                    <p style={{fontSize: '12px', marginTop: '-18px'}}>
                      {commonConstants.getDatesFromWeek(week)}
                    </p>
                  </React.Fragment>
                );
              }}
            />
            <Legend content={this.renderCusomizedLegend} wrapperStyle={{marginBottom: '-30px'}}/>

            {bars}
            {yearLines}

            <Line onMouseUp={this.activateLineTooltip}
                  onMouseOver={this.activateLineTooltip}
                  onMouseLeave={this.desactivateLineTooltip}
                  activeDot={{
                    onMouseOver: this.activateLineTooltip,
                    onMouseLeave: this.desactivateLineTooltip}}
                  type='monotone' strokeWidth={3} dataKey='tot_resp' name={historicoLabelName} stroke='black'/>
            <Line onMouseUp={this.activateLineTooltip}
                  onMouseOver={this.activateLineTooltip}
                  onMouseLeave={this.desactivateLineTooltip}
                  activeDot={{
                    onMouseOver: this.activateLineTooltip,
                    onMouseLeave: this.desactivateLineTooltip}}
                  type="monotone" strokeWidth={3} dataKey="forecastValue" name={"Pronóstico " + Config.appName} stroke="blue" strokeDasharray="5 5"/>
            <ReferenceDot onMouseUp={(e) => {
                            if(window.innerWidth > 700) {
                              this.activateLineTooltip(e);
                            }
                          }}
                          onMouseOver={(e) => {
                            if(window.innerWidth > 700) {
                              this.activateLineTooltip(e);
                            }
                          }}
                          onMouseLeave={this.desactivateLineTooltip}
                          x={todayRedCirclePoints.x} y={todayRedCirclePoints.y} r={5}
                          fill="red" stroke="none" label={{ position: 'top', value: 'Hoy', fill: 'red' }}/>

          </ComposedChart>
        </ResponsiveContainer>

        <Dialog open={chartDialogOpen}
                fullWidth
                aria-labelledby="simple-dialog-title">
          {/*<Paper className="paper">*/}
          <Button variant="contained"
                  color="secondary"
                  size="large"
                  onClick={this.handleChartDialogClose}
                  style={{position: 'fixed', top: '1px', right: '1px'}}>
            <CloseIcon style={{marginRight: 1}} />
            Cerrar
          </Button>
          {/*<DialogTitle id="simple-dialog-title">Semana Epidemiológica XX</DialogTitle>*/}
          {/*<DialogContent>*/}
            {this.state.dialogContent}
            {/*<DialogContentText id="alert-dialog-description">*/}
              {/*Let Google help apps determine location. This means sending anonymous location data to*/}
              {/*Google, even when no apps are running.*/}
            {/*</DialogContentText>*/}
            {/*<p>asdfsd</p>*/}
          {/*</DialogContent>*/}



          {/*</Paper>*/}
        </Dialog>
      </React.Fragment>

    );
  }
}


export default Chart;
