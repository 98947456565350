import commonConstants from '../constants/commonConstants';

const utils = {
  getDateOfISOWeek: (w) => {
    var y = new Date().getFullYear();
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());

    return utils.substractDays(ISOweekStart, 1);
  },

  addDays: (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  },

  substractDays: (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  },

  formatDate: (date) => {
    return date.toLocaleDateString("es-ES", commonConstants.dateFormatOptions);
  },

  formatDateRange: (startDate, endDate) => {
    // 13 de mayo de 2019 - 19 de mayo de 2019 ===> 13 al 19 de mayo de 2019
    let dateString = "";
    if ( startDate.getMonth() === endDate.getMonth() &&
         startDate.getFullYear() === endDate.getFullYear()) {
      dateString += startDate.getDate() + " al " + endDate.getDate() + " de ";
      dateString += startDate.toLocaleDateString("es-ES", { month: 'long', year: 'numeric' });
    }else if(startDate.getFullYear() === endDate.getFullYear()) {
      dateString += startDate.toLocaleDateString("es-ES", { day: 'numeric', month: 'long' }) + " al ";
      dateString += endDate.toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' });
    }else{
      dateString = utils.formatDate(startDate) + " al " + utils.formatDate(endDate);
    }
    return dateString;
  },


  getDatesFromWeek: (week) => {
    let startWeekDate = utils.getDateOfISOWeek(week);
    // let endWeekDate = utils.formatDate(utils.addDays(startWeekDate, 6));
    const endWeekDate = utils.addDays(startWeekDate, 6);
    return utils.formatDateRange(startWeekDate, endWeekDate);
    // startWeekDate = utils.formatDate(startWeekDate);
    // return `${startWeekDate} - ${endWeekDate}`;
  },

  objectMap: (object, mapFn) => {
    return Object.keys(object).reduce(function(result, key) {
      result[key] = mapFn(object[key]);
      return result
    }, {})
  },


};

export default utils;
