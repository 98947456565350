import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import comunasRm from '../../../constants/comunasRm';
import ChartYearCompareSelect from './ChartYearCompareSelect';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import commonConstants from '../../../constants/commonConstants';
import Tooltip from '../../../../node_modules/@material-ui/core/Tooltip/Tooltip';
import { errorMessages as messages } from '../../../constants/messages';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import BussinessTooltipText from '../UI/BussinessTooltipText';
import Hidden from '../../../../node_modules/@material-ui/core/Hidden/Hidden';
import Paper from '../../../../node_modules/@material-ui/core/Paper/Paper';
import RadioGroup from '../../../../node_modules/@material-ui/core/RadioGroup/RadioGroup';
import FormLabel from '../../../../node_modules/@material-ui/core/FormLabel/FormLabel';
import FormControlLabel from '../../../../node_modules/@material-ui/core/FormControlLabel/FormControlLabel';
import Radio from '../../../../node_modules/@material-ui/core/Radio/Radio';
import Modal from '../../../../node_modules/@material-ui/core/Modal/Modal';
import ChartContainer from '../../../containers/ChartContainer';
import Dialog from '../../../../node_modules/@material-ui/core/Dialog/Dialog';
import Toolbar from '../../../../node_modules/@material-ui/core/Toolbar/Toolbar';
import IconButton from '../../../../node_modules/@material-ui/core/IconButton/IconButton';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '../../../../node_modules/@material-ui/icons/Close';
import regiones from "../../../constants/regiones";
import {Bar} from "recharts";


const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing.unit / 4
  },
  group:{
    margin: `0 0`,
  },
  button: {

  },
  paper: {
    position: 'absolute',
    width: '70%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const getYearList = (startYear) => {
  var currentYear = new Date().getFullYear()-commonConstants.yearComparatorOffsetCurrent, years = [];
  startYear = startYear || commonConstants.yearComparatorInit;
  while ( startYear <= currentYear ) {
    years.push(currentYear--);
  }
  return years;
}

const yearComparatorNameList = getYearList();
//   [
//   "2019",
//   "2018",
//   "2017",
//   "2016",
//   "2015",
//   "2014",
//   "2013",
//   "2012",
//   "2011",
// ];



function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}
TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

class ChartBox extends React.Component {

  constructor(props) {
    super(props);
    this.state = commonConstants.DEFAULT_FILTERS;
    this.handleTooltipDialogClose = this.handleTooltipDialogClose.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.showMobileDialogTooltip = this.showMobileDialogTooltip.bind(this);


  }

  static propTypes = {
    fetchChartData: PropTypes.func.isRequired,
  };

  callToFetchChartData = () => {
    const { fetchChartData } = this.props;
    fetchChartData(this.state)
      .then(() => {})
      .catch((err) => {});
  };

  clearYears = (e) => {
    e.preventDefault();
    this.setState({ yearComparatorName: [], filterChanged: 'yearComparatorName'}, this.callToFetchChartData);
  };

  handleTabChange = (event, value) => {
    this.setState({ chartType: value, filterChanged: 'chartType', virus: commonConstants.VIRUS.TOT_RESP}, this.callToFetchChartData);
  };

  handleSelectChange = (event) => {
    let value = event.target.name === "chartType" ? parseInt(event.target.value) : event.target.value;
    if(event.target.name === "chartType"){
      const tooltipDialogContent = <BussinessTooltipText messageType={value}/>;
      this.setState({
        [event.target.name]: value,
        filterChanged: event.target.name,
        tooltipDialogContent: tooltipDialogContent,
        comuna: comunasRm['Todas'].id,
        virus: 'tot_resp',
      }, this.callToFetchChartData);
    }else{
      if (event.target.name === "region") {
        this.setState({comuna: commonConstants.TODAS_LAS_COMUNAS_ID, filterChanged: 'comuna'}, this.callToFetchChartData);
      }
      this.setState({ [event.target.name]: value, filterChanged: event.target.name }, this.callToFetchChartData);
    }

  };

  openModal = () => {
    this.setState({isModalOpen: true});
  };

  closeModal = () => {
    this.setState({isModalOpen: false});
  };



  modalContent = () => {
    const { classes } = this.props;
    return (
      <React.Fragment>

        <Dialog
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.isModalOpen}
          onClose={this.closeModal}
          fullScreen
        >

          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton color="inherit" onClick={this.closeModal} aria-label="ArrowBackIos">
                <ArrowBackIos />
              </IconButton>
              <Typography variant="h6" color="inherit" style={{flex: 1, marginLeft: '20px'}}>
                Filtros
              </Typography>
              <Button color="inherit" onClick={this.closeModal}>
                Ver Pronóstico
              </Button>
            </Toolbar>
          </AppBar>

          {this.filterContent()}

        </Dialog>

      </React.Fragment>
    );
  };

  handleTooltipDialogClose = () => {
    this.setState({ tooltipDialogOpen: false});
  };



  getMenuVirusesList = () => {
    return Object.entries(this.props.dataMapping.viruses).map(
      ([virusId, virus], i) =>
        <MenuItem value={virusId}>{virus.name}</MenuItem>
    )
  }

//     <MenuItem value={'VRS'}>VRS</MenuItem>
// <MenuItem value={'Ad'}>Adenovirus</MenuItem>
// <MenuItem value={'Inf_A'}>Influenza A</MenuItem>
// <MenuItem value={'Inf_B'}>Influenza B</MenuItem>
// <MenuItem value={'Para'}>Parainfluenza</MenuItem>
// <MenuItem value={'Meta'}>Metapneumovirus</MenuItem>


  filterContent = () => {
    const { classes } = this.props;
    const { chartType, infeccionesDataCategory, comuna, virus, yearComparatorName, region } = this.state;
    const regionsMenuItemList = Object.entries(regiones).map(([regionId, region]) => {
      return <MenuItem key={regionId} value={regionId}>{region.name}</MenuItem>
    });
    const comunasMenuItemList = Object.entries(regiones[region].comunas).map(([comunaId, comunaName]) =>
      <MenuItem key={comunaId} value={comunaId}>{comunaName}</MenuItem>
    );

    // const regionsMenuItemList = Object.values(regiones).map((region, key) => {
    //   console.log('region', region);
    //   console.log('key', key);
    //   return <MenuItem key={key} value={key}>{region.name}</MenuItem>
    // });
    const Wire = ({ children, ...props }) => children(props);

    return (
      <Grid container spacing={40}>
        <Grid item xs={12} sm={12} md={6}>
          <Paper className="paper">
            {/*<div style={{marginBottom: '20px'}}>*/}
            {/*  <FormControl>*/}
            {/*    <InputLabel htmlFor="select-region">Región</InputLabel>*/}
            {/*    <Select*/}
            {/*      style={{ minWidth: 200 }}*/}
            {/*      name="region"*/}
            {/*      value={this.state.region}*/}
            {/*      onChange={this.handleSelectChange}*/}
            {/*      inputProps={{*/}
            {/*        name: 'region',*/}
            {/*        id: 'select-location',*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      {regionsMenuItemList}*/}
            {/*    </Select>*/}
            {/*  </FormControl>*/}
            {/*</div>*/}

            { commonConstants.showChartTypeFilter &&

              <FormControl component="fieldset" className={classes.formControl} style={{marginTop: '-10px'}}>
                <RadioGroup
                  aria-label="Tipo de Gráfico"
                  name="chartType"
                  className={classes.group}
                  value={chartType}
                  onChange={this.handleSelectChange}
                >
                  <Wire value={commonConstants.CHART_TYPES.BY_VIRUS_RESPIRATORIOS}>
                    {props => (
                      <Tooltip title={<BussinessTooltipText
                        messageType={commonConstants.CHART_TYPES.BY_VIRUS_RESPIRATORIOS}/>}>
                        <FormControlLabel control={<Radio/>}
                                          label="Virus respiratorios"
                                          {...props}
                        />
                      </Tooltip>
                    )}
                  </Wire>
                  <Wire value={commonConstants.CHART_TYPES.BY_INFECCIONES_RESPIRATORIAS}>
                    {props => (
                      <Tooltip title={<BussinessTooltipText
                        messageType={commonConstants.CHART_TYPES.BY_INFECCIONES_RESPIRATORIAS}/>}>
                        <FormControlLabel control={<Radio/>}
                                          label="Cuadros respiratorios"
                                          style={{marginTop: '-20px'}}
                                          {...props}
                        />
                      </Tooltip>
                    )}
                  </Wire>

                </RadioGroup>
              </FormControl>

            }
            {/*</Hidden>*/}

            {/*<Hidden lgUp>*/}
            {/*<Paper className="pFormContrlaaa ctm</Paper>*/}
            {/*</Hidden>*/}

            <div style={{marginTop: '-5px'}}>
              {chartType === 0 &&
              <FormControl>
                <InputLabel htmlFor="select-virus"></InputLabel>
                <Select
                  style={{ minWidth: 200}}
                  name="virus"
                  value={virus}
                  onChange={this.handleSelectChange}
                  inputProps={{
                    name: 'virus',
                    id: 'select-virus',
                  }}
                >
                  <MenuItem value={'tot_resp'}>Total casos detectados</MenuItem>
                  {this.getMenuVirusesList()}
                </Select>
              </FormControl>
              }

              {chartType === 1 &&
              <Grid container spacing={16}>
                {/*<Grid item xs={12} sm={12} md={12} lg={6}>*/}
                {/*  <Tooltip*/}
                {/*    disableHoverListener={comuna === commonConstants.TODAS_LAS_COMUNAS_ID}*/}
                {/*    title="Para cambiar este filtro, primero debes seleccionar 'Todas las comunas'">*/}
                {/*    <FormControl>*/}
                {/*      <InputLabel htmlFor="select-categoria">Cuadros respiratorios</InputLabel>*/}
                {/*      <Select*/}
                {/*        disabled={comuna !== commonConstants.TODAS_LAS_COMUNAS_ID}*/}
                {/*        style={{ minWidth: 200 }}*/}
                {/*        name="infeccionesDataCategory"*/}
                {/*        value={infeccionesDataCategory}*/}
                {/*        onChange={this.handleSelectChange}*/}
                {/*        inputProps={{*/}
                {/*          name: 'infeccionesDataCategory',*/}
                {/*          id: 'select-categoria',*/}
                {/*        }}*/}
                {/*      >*/}
                {/*        <MenuItem value={commonConstants.INFECCIONES_DATA_CATEGORY.POR_CUADRO_RESPIRATORIO}>Por tipo de cuadro</MenuItem>*/}
                {/*        <MenuItem value={commonConstants.INFECCIONES_DATA_CATEGORY.POR_GRUPO_ETARIO}>Por grupo etario</MenuItem>*/}
                {/*      </Select>*/}
                {/*    </FormControl>*/}
                {/*  </Tooltip>*/}
                {/*</Grid>*/}

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <FormControl>
                    <InputLabel htmlFor="select-comunas">Comunas</InputLabel>
                    <Select
                      style={{ minWidth: 200 }}
                      name="comuna"
                      value={comuna}
                      onChange={this.handleSelectChange}
                      inputProps={{
                        name: 'comuna',
                        id: 'select-location',
                      }}
                    >
                      {comunasMenuItemList}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              }
            </div>
          </Paper>

        </Grid>
        <Grid item xs={12} sm={12} md={6} >
          <Grid container alignItems="flex-start" justify="flex-end" direction="row">
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="select-multiple-chip">Comparar años</InputLabel>
              <Select
                multiple
                name="yearComparatorName"
                value={yearComparatorName}
                onChange={this.handleSelectChange}
                input={<Input id="select-multiple-chip" />}
                style={{minWidth: 150}}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <Chip key={value} label={value} className={classes.chip} />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {yearComparatorNameList.map(name => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={yearComparatorName.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
              {
                yearComparatorName.length !== 0 &&
                <Button variant="contained" color="primary" onClick={this.clearYears}>Dejar de comparar</Button>
              }
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  showMobileDialogTooltip() {
    this.setState({tooltipDialogOpen: true});
  }

  render() {
    const { classes } = this.props;
    const { chartType, infeccionesDataCategory, comuna, virus, yearComparatorName,
      tooltipDialogOpen,
      tooltipDialogContent } = this.state;

    let chartTypeName = "Virus respiratorios";
    let infCategoryElement = "";
    let tooltipTitle = "";
    if (chartType === commonConstants.CHART_TYPES.BY_VIRUS_RESPIRATORIOS){
      if(virus === commonConstants.VIRUS.TOT_RESP) {
        chartTypeName = "Virus respiratorios";
      } else {
        chartTypeName = "Virus - " + this.props.dataMapping.viruses[virus].name;
      }
      // switch (virus) {
      //   case commonConstants.VIRUS.TOT_RESP:
      //     break;
      //   case commonConstants.VIRUS.AD:
      //     chartTypeName = "Virus - Adenovirus"
      //     break;
      //   case commonConstants.VIRUS.INF_A:
      //     chartTypeName = "Virus - Influenza A"
      //     break;
      //   case commonConstants.VIRUS.INF_B:
      //     chartTypeName = "Virus - Influenza B"
      //     break;
      //   case commonConstants.VIRUS.META:
      //     chartTypeName = "Virus - Metapneumovirus"
      //     break;
      //   case commonConstants.VIRUS.PARA:
      //     chartTypeName = "Virus - Parainfluenza"
      //     break;
      //   case commonConstants.VIRUS.VRS:
      //     chartTypeName = "Virus - VRS"
      //     break;
      // }
      tooltipTitle = <BussinessTooltipText messageType={commonConstants.CHART_TYPES.BY_VIRUS_RESPIRATORIOS}/>

    } else if(chartType === commonConstants.CHART_TYPES.BY_INFECCIONES_RESPIRATORIAS) {
      chartTypeName = "Cuadros respiratorios";
      let infCategoryName = infeccionesDataCategory === commonConstants.INFECCIONES_DATA_CATEGORY.POR_CUADRO_RESPIRATORIO ?
        "Por tipo de cuadro" : "Por grupo etario";
      tooltipTitle = <BussinessTooltipText messageType={commonConstants.CHART_TYPES.BY_INFECCIONES_RESPIRATORIAS}/>
      infCategoryElement = comuna === commonConstants.TODAS_LAS_COMUNAS_ID ?
                          (<Chip label={infCategoryName} variant="outlined"/>) : "";
    }
    const chartTypeElement = (<Chip label={chartTypeName} variant="outlined"/>);



    return (
      <React.Fragment>
        <p style={{fontSize: '10pt', textAlign: 'center'}}>{messages.header}</p>

        <hr/>
        {/*<Breadcrumb>*/}
          {/*<BreadcrumbItem>Chile</BreadcrumbItem>*/}
          {/*<BreadcrumbItem active={comuna === commonConstants.TODAS_LAS_COMUNAS_ID}>*/}
          {/*  { comuna === commonConstants.TODAS_LAS_COMUNAS_ID &&*/}
          {/*    regiones[this.state.region].name*/}
          {/*  }*/}
          {/*  { comuna !== commonConstants.TODAS_LAS_COMUNAS_ID &&*/}
          {/*    <a href="#" onClick={() => {*/}
          {/*      const customEventData = {*/}
          {/*        target:{*/}
          {/*          name: 'comuna',*/}
          {/*          value: commonConstants.TODAS_LAS_COMUNAS_ID*/}
          {/*        }*/}
          {/*      };*/}
          {/*      this.handleSelectChange(customEventData);*/}
          {/*    }*/}
          {/*    }>{regiones[this.state.region].name}</a>*/}
          {/*  }*/}
          {/*</BreadcrumbItem>*/}
          {/*{ comuna !== commonConstants.TODAS_LAS_COMUNAS_ID &&*/}
          {/*  <BreadcrumbItem active>*/}
          {/*    <strong>{ comunasRm[comuna].name }</strong>*/}
          {/*  </BreadcrumbItem>*/}
          {/*}*/}
        {/*</Breadcrumb>*/}

        {/*<Grid container>*/}
        {/*  <Paper className="paper">*/}
        {/*    <Grid item xs={12} sm={12} md={12} lg={6}>*/}
        {/*      <FormControl>*/}
        {/*        <InputLabel htmlFor="select-region">Región</InputLabel>*/}
        {/*        <Select*/}
        {/*          style={{ minWidth: 200 }}*/}
        {/*          name="region"*/}
        {/*          value={this.state.region}*/}
        {/*          onChange={this.handleSelectChange}*/}
        {/*          inputProps={{*/}
        {/*            name: 'region',*/}
        {/*            id: 'select-location',*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          {regionsMenuItemList}*/}
        {/*        </Select>*/}
        {/*      </FormControl>*/}
        {/*    </Grid>*/}
        {/*  </Paper>*/}
        {/*</Grid>*/}

        <Hidden smUp>
          <Grid container direction="row">
            <Grid item xs={6}>
              <Grid container spacing={0} direction="row" justify="flex-start" alignItems="center">
                <Grid item onClick={this.showMobileDialogTooltip}>
                  {chartTypeElement}
                  {infCategoryElement}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={0} direction="row" justify="flex-end" alignItems="center">
                <Grid item>
                  <Button fullWidth={true} variant="contained" color="primary" className={classes.button} onClick={this.openModal}>
                    Filtros
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>

        <Hidden xsDown>
          {this.filterContent()}
        </Hidden>

        {this.modalContent()}

        <Dialog open={tooltipDialogOpen}
                onClose={this.handleTooltipDialogClose}
                aria-labelledby="simple-dialog-title">
          <Button variant="contained"
                  color="secondary"
                  size="large"
                  onClick={this.handleTooltipDialogClose}
                  style={{position: 'fixed', top: '1px', right: '1px'}}>
            <CloseIcon style={{marginRight: 1}} />
            Cerrar
          </Button>
          <div style={{margin: 15}}>
            <BussinessTooltipText messageType={chartType}/>
          </div>
        </Dialog>

      </React.Fragment>
    );
  }

}

export default withStyles(styles)(ChartBox);
