import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getMapData } from '../actions/map';

class MapContainer extends Component {
  static propTypes = {
    Layout: PropTypes.func.isRequired,
    mapData: PropTypes.shape({}).isRequired,
    fetchMapData: PropTypes.func.isRequired
  };

  static defaultProps = {
    // filters: commonConstants.DEFAULT_FILTERS
  };

  state = {
    error: null,
    loading: true,
  };

  componentDidMount = () => this.fetchMapData();

  fetchMapData = () => {
    // console.log("DEBUG MapContainer.js -> calling to action map -> getMapData");
    const { fetchMapData } = this.props;
    this.setState({ loading: true });
    return fetchMapData()
      .then((resp) => {
        // console.log("DEBUG MapContainer.js -> fetchMapData -> resp: ", resp);
        this.setState({
          loading: false,
          error: null,
        });
      }).catch(err => this.setState({
        loading: false,
        error: err,
      }));
  };

  render = () => {
    const { Layout, mapData } = this.props;
    const { loading, error } = this.state;

    return (
      <Layout
        error={error}
        loading={loading}
        mapData={mapData}
      />
    );
  }
}

const mapStateToProps = state => ({
  mapData: state.mapReducer.mapData || []
});

const mapDispatchToProps = {
  fetchMapData: getMapData,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
