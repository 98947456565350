import { Firebase, FirebaseRef } from '../lib/firebase';
import commonConstants from '../constants/commonConstants';


const mapInfeccionesDataCategoryUrlParam = (filters) => {
  if( filters.infeccionesDataCategory === commonConstants.INFECCIONES_DATA_CATEGORY.POR_CUADRO_RESPIRATORIO){
    return "by_disease";
  }

  if( filters.infeccionesDataCategory === commonConstants.INFECCIONES_DATA_CATEGORY.POR_GRUPO_ETARIO){
    return "by_age";
  }
};

const getFirebaseUrlWithFilters = (filters) => {
  const baseUrl = commonConstants.FIREBASE_MAIN_DATA_BASE_URL;
  if (filters.chartType === commonConstants.CHART_TYPES.BY_INFECCIONES_RESPIRATORIAS) {
    let chartTypeUrl = baseUrl + "by_infecciones/";
    if (filters.yearComparatorName.length > 0) {
      return chartTypeUrl + `by_years/${filters.region}/${filters.comuna}`;
    }
    return chartTypeUrl
      + mapInfeccionesDataCategoryUrlParam(filters) + "/"
      + filters.region + "/"
      + filters.comuna;
  }
  if(filters.chartType === commonConstants.CHART_TYPES.BY_VIRUS_RESPIRATORIOS) {
    if(filters.yearComparatorName.length === 0){
      return `${baseUrl}by_virus/${filters.region}`;
    }else{
      return `${baseUrl}by_virus/by_years/${filters.region}/${filters.virus}`;
    }
  }
};

const useLocalData = (filters) => {
  return filters.filterChanged === 'virus' && filters.yearComparatorName.length === 0;
};

export function getDataMapping() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve =>
    FirebaseRef.child('data_mapping').on('value', (snapshot) => {
      let dataMapping = snapshot.val() || {};
      return resolve(dispatch({
        type: 'DATA_MAPPING',
        dataMapping
      }));
    })).catch(() => {});
}
