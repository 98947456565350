import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { errorMessages as messages } from '../../../constants/messages';
import commonConstants from '../../../constants/commonConstants';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
});

class Privacy extends React.Component{
  render() {
    const { messageType } = this.props;
    let header = '', body = '';
    if(messageType === commonConstants.CHART_TYPES.BY_VIRUS_RESPIRATORIOS){
      header = messages.virusTooltipHeader;
      body = messages.virusTooltip;
    }else if(messageType === commonConstants.CHART_TYPES.BY_INFECCIONES_RESPIRATORIAS){
      header = messages.infeccionesTooltipHeader;
      body = messages.infeccionesTooltip;
    }
    return (
      <React.Fragment>
        <p><strong style={{fontSize: '11pt'}}>{header}</strong></p>
        <p style={{fontSize: '9pt'}}>{body}</p>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Privacy);







