const regiones = {
  "biobio" : {
    "comunas" : {
      // "Todas": "Todas las comunas",
      "Chiguayante" : "Chiguayante",
      "Concepcion" : "Concepción",
      "Coronel" : "Coronel",
      "Hualpen" : "Hualpen",
      "Hualqui" : "Hualqui",
      "Los_Angeles" : "Los Ángeles",
      "Lota" : "Lota",
      "Penco" : "Penco",
      "San_Pedro_de_la_Paz" : "San Pedro de la Paz",
      "Talcahuano" : "Talcahuano",
      "Tome" : "Tome",
      "Chillan_Mix" : "Chillan - Chillan Viejo",
      "San_Carlos" : "San Carlos"
    },
    "name" : "Región del Biobio"
  },
  "nuble" : {
    "comunas" : {
      "Todas": "Todas las comunas",
      "Chillan_Mix" : "Chillan - Chillan Viejo",
      "San_Carlos" : "San Carlos"
    },
    "name" : "Región de Ñuble"
  }
};

export default regiones;
