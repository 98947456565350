import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { withStyles } from '@material-ui/core/styles';

import AboutSection from './AboutSection';
import ChartSection from './Chart/ChartSection';
import MapSection from './Map/MapSection';
import ContactSection from './ContactSection';
import MethodologySection from './MethodologySection';
import LoginModal from './User/LoginModal';
import Member from '../../containers/Member';
import ChartContainer from '../../containers/ChartContainer';
import ScrollableAnchor from 'react-scrollable-anchor';
import {connect} from "react-redux";
import commonConstants from "../../constants/commonConstants";
import {getChartData} from "../../actions/chart";
import CircularProgress from "@material-ui/core/CircularProgress";
import {getDataMapping} from "../../actions/dataMapping";
import Grid from "@material-ui/core/Grid";
import dataMappingReducer from "../../reducers/dataMapping";

const styles = theme => ({
  root: {
  },
});

class Home extends React.Component{

  updateMargin (){
    var height = document.getElementById("main-navbar").offsetHeight;
    document.getElementById("root").style.marginTop = height + 'px';
    document.getElementById("sidebar-menu").style.top = height + 'px';
    // console.log("navbar height: ", height);
  };
  componentDidMount = () => {
    this.props.getDataMapping();
    this.updateMargin();
    window.addEventListener("resize", this.updateMargin.bind(this));
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateMargin.bind(this));
  };

  render() {
    const { classes } = this.props;

    if(!this.props.dataMapping){
      return (
        <>
          <br/><br/><br/>
          <Grid container justify='center' alignItems='center'>
            <Grid item>
              <CircularProgress/>
            </Grid>
          </Grid>
        </>
      );
    }

    return (
      <div className={classes.root}>

        <ScrollableAnchor id={'pronostico'}>
          <ChartContainer Layout={ChartSection}/>
        </ScrollableAnchor>

        <ScrollableAnchor id={'mapa'}>
          <MapSection/>
        </ScrollableAnchor>

        <ScrollableAnchor id={'metodologia'}>
          <MethodologySection/>
        </ScrollableAnchor>

        <ScrollableAnchor id={'about'}>
          <AboutSection />
        </ScrollableAnchor>

        <ScrollableAnchor id={'contacto'}>
          <Member Layout={ContactSection} />
        </ScrollableAnchor>

        <Member Layout={LoginModal} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dataMapping: state.dataMappingReducer.dataMapping || null,
});

const mapDispatchToProps = {
  getDataMapping: getDataMapping,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Home));

// export default withStyles(styles)(Home);
// export default connect(mapStateToProps, mapDispatchToProps)(Home);
