import React from 'react';
import { Row, Col } from 'reactstrap';
import Grid from '../../../../node_modules/@material-ui/core/Grid/Grid';
import logoEscudoUdec from '../../../images/logos/logo-escudo-udec.gif';
import Config from "../../../constants/config";

const Footer = () => (
    <footer className="mt-5">
      <Grid className="client-logo-container"
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            spacing={40}
            style={{marginTop: '20px'}}>
        <Grid item>
          <a href="http://udec.cl" target='_blank'>
            <img width={150} src={logoEscudoUdec} alt="logoEscudoUdec"/>
          </a>
        </Grid>
      </Grid>
      <br/>

      <Row>
        <Col sm="12" className="text-right pt-3">

          <p>
            {' '}
            <a href="/terminos-de-uso">
              Términos de uso
            </a>
            {' '}
            &nbsp; | &nbsp;
            {' '}
            <a href="/politica-de-privacidad">
              Política de Privacidad
            </a>
            {' '}
            &nbsp; | &nbsp;
            {' '}
            <a href="cookies">
              Política de Cookies
            </a>

          </p>
        </Col>
      </Row>



      <Row>
        <Col sm="12" className="text-right pt-3">

          <p>Universidad de Concepción, Víctor Lamas 1290, Concepción, Región del Bío Bío, Chile</p>

          <p>
            © {new Date().getFullYear()}
            {' '}
            <a target="_blank" rel="noopener noreferrer" href="/">
              {Config.appName}
            </a>
            {' '}
            &nbsp; | &nbsp; Sitio hecho por
            {' '}
            <a target="_blank" rel="noopener noreferrer" href="https://fisfor.cl">
              Fisfor
            </a>
            .
          </p>
        </Col>
      </Row>


    </footer>
);

export default Footer;
