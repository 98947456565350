import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LoginModal from './User/LoginModal';
import Member from '../../containers/Member';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
});

class Privacy extends React.Component{
  render() {
    const { classes } = this.props;

    return (
      <div className={"justifyText policyMargenTop"}>

          <h2>Política de Cookies</h2>
          <div className="">
            <div className="">

              <p> En esta web se utilizan cookies de terceros y propias para conseguir que tengas una mejor experiencia
                de navegación, puedas compartir contenido en redes sociales y para que podamos obtener estadísticas de
                los usuarios.<br/><br/>
                  Puedes evitar la descarga de cookies a través de la configuración de tu navegador, evitando que las
                  cookies se almacenen en su dispositivo.<br/><br/>
                  Como propietario de este sitio web, te comunico que no utilizamos ninguna información personal
                  procedente de cookies, tan sólo realizamos estadísticas generales de visitas que no suponen ninguna
                  información personal.<br/><br/>
                  Es muy importante que leas la presente política de cookies y comprendas que, si continúas navegando,
                  consideraremos que aceptas su uso.<br/><br/>
                  Según los términos incluidos en el artículo 22.2 de la Ley 34/2002 de Servicios de la Sociedad de la
                  Información y Comercio Electrónico, si continúas navegando, estarás prestando tu consentimiento para
                  el empleo de los referidos mecanismos.</p>


              <h3>Entidad Responsable</h3>
              <p className="ng-binding">La entidad responsable de la recogida, procesamiento y utilización de tus datos personales, en
                el sentido establecido por la Ley de Protección de Datos Personales es la página www.udec.cl,
                propiedad de Universidad de Concepción – Víctor Lamas 1290, Concepción, Región del Bío
                Bío, Chile.</p>

              <h3>¿Qué son las cookies?</h3>
              <p>Las cookies son un conjunto de datos que un servidor deposita en el navegador del usuario para recoger
                la información de registro estándar de Internet y la información del comportamiento de los visitantes en
                un sitio web. Es decir, se trata de pequeños archivos de texto que quedan almacenados en el disco duro
                del ordenador y que sirven para identificar al usuario cuando se conecta nuevamente al sitio web. Su
                objetivo es registrar la visita del usuario y guardar cierta información. Su uso es común y frecuente en
                la web ya que permite a las páginas funcionar de manera más eficiente y conseguir una mayor
                personalización y análisis sobre el comportamiento del usuario.</p>
              <h3>¿Qué tipos de cookies existen?</h3>

              <p>Las cookies utilizadas en nuestro sitio web, son de sesión y de terceros, y nos permiten almacenar y
                acceder a información relativa al idioma, el tipo de navegador utilizado, y otras características
                generales predefinidas por el usuario, así como, seguir y analizar la actividad que lleva a cabo, con el
                objeto de introducir mejoras y prestar nuestros servicios de una manera más eficiente y
                personalizada. <br/><br/>

              </p><p className="ng-binding">Las cookies, en función de su permanencia, pueden dividirse en cookies de
              sesión o permanentes. Las que expiran cuando el usuario cierra el navegador. Las que expiran en función de
              cuando se cumpla el objetivo para el que sirven (por ejemplo, para que el usuario se mantenga identificado
              en los servicios de
              Universidad de Concepción) o bien cuando se borran manualmente. </p>
              <p></p>

              <table border="0" width="100%" cellPadding="15">
                <tbody>
                <tr>
                  <td>Nombre</td>
                  <td>Tipo</td>
                  <td>Caducidad</td>
                  <td>Finalidad</td>
                  <td>Clase</td>
                </tr>
                <tr>
                  <td>__utma</td>
                  <td>De Terceros (Google Analytics)</td>
                  <td>2 años</td>
                  <td>Se usa para distinguir usuarios y sesiones.</td>
                  <td>No Exenta</td>
                </tr>
                <tr>
                  <td>__utmb</td>
                  <td>De Terceros (Google Analytics)</td>
                  <td>30 minutos</td>
                  <td>Se usa para determinar nuevas sesiones o visitas</td>
                  <td>No Exenta</td>
                </tr>
                <tr>
                  <td>__utmc</td>
                  <td>De Terceros (Google Analytics)</td>
                  <td>Al finalizar la sesión</td>
                  <td>Se configura para su uso con Urchin</td>
                  <td>No Exenta</td>
                </tr>
                <tr>
                  <td>__utmz</td>
                  <td>De Terceros (Google Analytics)</td>
                  <td>6 meses</td>
                  <td>Almacena el origen o la campaña que explica cómo el usuario ha llegado hasta la página web</td>
                  <td>No Exenta</td>
                </tr>
                </tbody>
              </table>


              <p className="ng-binding">Adicionalmente, en función de su objetivo, las cookies pueden clasificarse de la
                siguiente forma:</p>
              <h3>Cookies de rendimiento</h3>
              <p>Este tipo de Cookie recuerda sus preferencias para las herramientas que se encuentran en los servicios,
                por lo que no tiene que volver a configurar el servicio cada vez que usted visita. A modo de ejemplo, en
                esta tipología se incluyen: Ajustes de volumen de reproductores de vídeo o sonido. Las velocidades de
                transmisión de vídeo que sean compatibles con su navegador. Los objetos guardados en el “carrito de la
                compra” en los servicios de e-commerce tales como tiendas.</p>
              <h3>Cookies de geo-localización</h3>
              <p>Estas cookies son utilizadas para averiguar en qué país se encuentra cuando se solicita un servicio.
                Esta cookie es totalmente anónima, y sólo se utiliza para ayudar a orientar el contenido a su
                ubicación.</p>
              <h3>Cookies de registro</h3>
              <p>Las cookies de registro se generan una vez que el usuario se ha registrado o posteriormente ha abierto
                su sesión, y se utilizan para identificarle en los servicios con los siguientes objetivos:</p>
              <p>Mantener al usuario identificado de forma que, si cierra un servicio, el navegador o el ordenador y en
                otro momento u otro día vuelve a entrar en dicho servicio, seguirá identificado, facilitando así su
                navegación sin tener que volver a identificarse. Esta funcionalidad se puede suprimir si el usuario
                pulsa la funcionalidad [cerrar sesión], de forma que esta cookie se elimina y la próxima vez que entre
                en el servicio el usuario tendrá que iniciar sesión para estar identificado.</p>
              <p>Comprobar si el usuario está autorizado para acceder a ciertos servicios, por ejemplo, para participar
                en un concurso.</p>
              <p>Adicionalmente, algunos servicios pueden utilizar conectores con redes sociales tales como Facebook o
                Twitter. Cuando el usuario se registra en un servicio con credenciales de una red social, autoriza a la
                red social a guardar una Cookie persistente que recuerda su identidad y le garantiza acceso a los
                servicios hasta que expira. El usuario puede borrar esta Cookie y revocar el acceso a los servicios
                mediante redes sociales actualizando sus preferencias en la red social que específica.</p>
              <h3>Cookies de analíticas</h3>
              <p className="ng-binding">Cada vez que un usuario visita un servicio, una herramienta de un proveedor
                externo genera una cookie analítica en el ordenador del usuario. Esta cookie que sólo se genera en la
                visita, servirá en próximas visitas a los servicios de
                Universidad de Concepción.
                para identificar de forma anónima al visitante. Los objetivos principales que se persiguen son:</p>
              <p>Permitir la identificación anónima de los usuarios navegantes a través de la cookie (identifica
                navegadores y dispositivos, no personas) y por lo tanto la contabilización aproximada del número de
                visitantes y su tendencia en el tiempo.<br/>
                  Identificar de forma anónima los contenidos más visitados y por lo tanto más atractivos para los
                  usuarios Saber si el usuario que está accediendo es nuevo o repite visita.</p>
              <p className="ng-binding">Importante: Salvo que el usuario decida registrarse en un servicio de
                Universidad de Concepción, la cookie nunca irá asociada a ningún dato de carácter personal que pueda
                identificarle. Dichas cookies sólo serán utilizadas con propósitos estadísticos que ayuden a la
                optimización de la experiencia de los usuarios en el sitio.</p>

              <h3>Cookies de publicidad</h3>
              <p className="ng-binding">Este tipo de cookies permiten ampliar la información de los anuncios mostrados a
                cada usuario anónimo en los servicios de
                Universidad de Concepción. Entre otros, se almacena la duración o frecuencia de visualización de posiciones
                publicitarias, la interacción con las mismas, o los patrones de navegación y/o comportamientos del
                usuario ya que ayudan a conformar un perfil de interés publicitario. De este modo, permiten ofrecer
                publicidad afín a los intereses del usuario.</p>
              <h3>Cookies publicitarias de terceros</h3>
              <p className="ng-binding">Además de la publicidad gestionada por las webs de
                Universidad de Concepción
                en sus servicios, las webs de
                Universidad de Concepción
                ofrecen a sus anunciantes la opción de servir anuncios a través de terceros (“Ad-Servers”). De este
                modo, estos terceros pueden almacenar cookies enviadas desde los servicios de
                Universidad de Concepción
                procedentes de los navegadores de los usuarios, así como acceder a los datos que en ellas se
                guardan.</p>
              <p className="ng-binding">Las empresas que generan estas cookies tienen sus propias políticas de
                privacidad. En la actualidad, las webs de
                Universidad de Concepción
                utilizan la plataforma Doubleclick (Google) para gestionar estos servicios. Para más información, acuda
                a</p>
              <p>
                <a
                  href="http://www.google.es/policies/privacy/ads/#toc-doubleclick">http://www.google.es/policies/privacy/ads/#toc-doubleclick</a>
                y a
                <a href="http://www.google.es/policies/privacy/ads/">http://www.google.es/policies/privacy/ads/</a>.</p>
              <h3>¿Cómo puedo deshabilitar las cookies en mi navegador?</h3>
              <p>Se pueden configurar los diferentes navegadores para avisar al usuario de la recepción de cookies y, si
                se desea, impedir su instalación en el equipo. Asimismo, el usuario puede revisar en su navegador qué
                cookies tiene instaladas y cuál es el plazo de caducidad de las mismas, pudiendo eliminarlas.</p>
              <p>Para ampliar esta información consulte las instrucciones y manuales de su navegador:</p>
              <p>Para más información sobre la administración de las cookies en Google Chrome:
                <a
                  href="https://support.google.com/chrome/answer/95647?hl=es">https://support.google.com/chrome/answer/95647?hl=es</a>
              </p>
              <p>Para más información sobre la administración de las cookies en Internet Explorer:
                <a href="http://windows.microsoft.com/es-es/windows-vista/cookies-frequently-asked-question"></a>
                <a
                  href="http://windows.microsoft.com/es-es/windows-vista/cookies-frequently-asked-questions">http://windows.microsoft.com/es-es/windows-vista/cookies-frequently-asked-questions</a>
              </p>
              <p>
                <a href="http://windows.microsoft.com/es-es/windows-vista/cookies-frequently-asked-question"></a>Para
                más información sobre la administración de las cookies en Mozilla Firefox:
                <a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we%20">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we
                </a>
              </p>
              <p>Para más información sobre la administración de las cookies en Safari:
                <a href="http://www.apple.com/es/privacy/use-of-cookies/%20">http://www.apple.com/es/privacy/use-of-cookies/
                </a>
              </p>
              <p>Para más información sobre la administración de las cookies en Opera:
                <a
                  href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">http://help.opera.com/Windows/11.50/es-ES/cookies.html</a>
              </p>
              <p>Si desea dejar de ser seguido por Google Analytics visite:
                <a href="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout</a>
              </p>
              <h3>Para saber más sobre las cookies</h3>
              <p>Puede obtener más información sobre la publicidad online basada en el comportamiento y la privacidad
                online en el siguiente enlace:
                <a href="http://www.youronlinechoices.com/es/">http://www.youronlinechoices.com/es/</a>
              </p>
              <p>Protección de datos de Google Analytics:
                <a
                  href="http://www.google.com/analytics/learn/privacy.html">http://www.google.com/analytics/learn/privacy.html</a>
              </p>
              <p>Cómo usa Google Analytics las cookies:
                <a
                  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es#analyticsjs">https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es#analyticsjs</a>
              </p>
              <h3>Actualizaciones y cambios en la política de privacidad/cookies</h3>
              <p className="ng-binding">Las webs de
                Universidad de Concepción
                pueden modificar esta Política de Cookies en función de exigencias legislativas, reglamentarias, o con
                la finalidad de adaptar dicha política a las instrucciones dictadas por la Agencia Española de
                Protección de Datos, por ello se aconseja a los usuarios que la visiten periódicamente.</p>
              <p>Cuando se produzcan cambios significativos en esta Política de Cookies, estos se comunicarán a los
                usuarios bien mediante la web o a través de correo electrónico a los usuarios registrados.</p>
            </div>

          </div>

        <Member Layout={LoginModal} />
      </div>
    );
  }
}

export default withStyles(styles)(Privacy);







