import Config from "./config";

export const generalMessages = {};

export const successMessages = {};

export const errorMessages = {
  // Defaults
  default: 'Hmm, an unknown error occured',
  timeout: 'Server Timed Out. Check your internet connection',
  invalidJson: 'Response returned is not valid JSON',

  // Firebase Related
  invalidFirebase: 'Firebase is not connected correctly',

  // Member
  memberExists: 'Member already exists',
  missingFirstName: 'First name is missing',
  missingLastName: 'Last name is missing',
  missingEmail: 'Email is missing',
  missingProfesion: 'Debes agregar una profesión',
  missingInstitucion: 'Debes agregar una institución',

  missingPassword: 'Password is missing',
  passwordsDontMatch: 'Passwords do not match',

  // Recipes
  recipe404: 'Recipe not found',
  missingMealId: 'Missing meal definition',

  // Charts
  chartData404: 'Data not found',

  infeccionesTooltipHeader: 'Número de consultas ambulatorias en urgencias públicas de la RM.',
  infeccionesTooltip: `La curva negra (Datos DEIS) corresponde a datos del DEIS (Departamento de Estadísticas e Información de Salud). La curva azul (pronóstico) corresponde a lo pronosticado por ${Config.appName}.`,
  virusTooltipHeader: 'Número de casos detectados en la red de vigilancia de la RM (por centros centinelas privados).',
  virusTooltip: `La curva negra (Datos ISP) corresponde al reporte viral del ISP (Instituto de Salud Pública). La curva azul (pronóstico) corresponde a lo pronosticado por ${Config.appName}.`,

  header: `${Config.appName} es un pronóstico de consultas por enfermedades respiratorias para la red asstencial de salud basado en modelos epidemiológicos, variables meteorológicas y calidad del aire con el propósito de apoyar a los profesionales del área de la salud en anticipar consultas de urgencia por cuadros respiratorios durante el invierno.`
};
