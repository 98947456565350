export default {

  // filters: {
  //   infeccionesDataCategory: 1,
  //   comuna: 0,
  //   virus: 1,
  //   tabIndex: 0,
  //   yearComparatorName: []
  // },

  chartData: [  ],
};
