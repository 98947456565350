import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  Form,
  Label,
  Alert,
  Input,
  Button,
  CardBody,
  FormGroup,
  CardHeader,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import FormControlLabel from '../../../../node_modules/@material-ui/core/FormControlLabel/FormControlLabel';
import Checkbox from '../../../../node_modules/@material-ui/core/Checkbox/Checkbox';
import LoginContainer from '../../../containers/Login';
import LoginComponent from './Login';
import Modal from '../../../../node_modules/@material-ui/core/Modal/Modal';
import compose from 'recompose/compose';

import { withStyles } from '@material-ui/core';
import CloseIcon from '../../../../node_modules/@material-ui/icons/Close';
import Dialog from '../../../../node_modules/@material-ui/core/Dialog/Dialog';
import TermsOfService from '../TermsOfService';
import Privacy from '../Privacy';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
});

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

class SignUp extends React.Component {
  static propTypes = {
    error: PropTypes.string,
    success: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    onFormSubmit: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {
    error: null,
    success: null,
  };

  state = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    password2: '',
    profesion: '',
    institucion1: '',
    institucion2: '',
    agreeTerms: false,
    modalOpen: false,
    modalView: 'terms',
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = (event) => {
    event.preventDefault();
    const { onFormSubmit, history } = this.props;

    onFormSubmit(this.state)
      .then(() => setTimeout(() => history.push('/'), 200))
      .catch(() => {});
  };

  handleCheckboxChange = event => {
    this.setState({ agreeTerms: event.target.checked });
  };

  handleCloseModal = () => {
    this.setState({modalOpen: false});
  };

  isAllFieldsFilled = () => {
    return (
      this.state.firstName != '' &&
      this.state.email != '' &&
      this.state.profesion != '' &&
      this.state.institucion1 != '' &&
      this.state.agreeTerms);
  };

  showTerms = () => {
    this.setState({modalView: 'terms', modalOpen: true});
  };

  showPrivacy = () => {
    this.setState({modalView: 'privacy', modalOpen: true});
  };

  render() {
    const { loading, error, success, classes } = this.props;
    const {
      firstName, lastName, email, profesion, institucion1, institucion2,
      password, password2, agreeTerms
    } = this.state;

    const agreeLabel = (
      <div> <span>He leído y acepto los </span>
      <Link to="#" onClick={this.showTerms}>términos de uso</Link>
      <span> y la </span>
      <Link to="#" onClick={this.showPrivacy}>política de privacidad</Link>
      <span>.</span> </div> );

    return (
      <div>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <Card>
              <CardHeader>
                <strong>Registro de cuenta</strong>
                <p style={{fontSize: '10pt'}}>Sólo te tomará unos segundos</p>
              </CardHeader>

              <CardBody>
                {!!error && <Alert color="danger">{error}</Alert>}
                {!!success && <Alert color="success">{success}</Alert>}

                <Form onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <Label for="firstName">Nombre y Apellido*</Label>
                    <Input
                      type="text"
                      name="firstName"
                      id="firstName"
                      disabled={loading}
                      value={firstName}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  {/*<FormGroup>*/}
                    {/*<Label for="lastName">Apellido *</Label>*/}
                    {/*<Input*/}
                      {/*type="text"*/}
                      {/*name="lastName"*/}
                      {/*id="lastName"*/}
                      {/*disabled={loading}*/}
                      {/*value={lastName}*/}
                      {/*onChange={this.handleChange}*/}
                    {/*/>*/}
                  {/*</FormGroup>*/}

                  <FormGroup>
                    <Label for="email">Email *</Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      disabled={loading}
                      value={email}
                      onChange={this.handleChange}
                    />
                  </FormGroup>

                  <FormGroup>
                  <Label for="profesion">Profesión / Especialidad *</Label>
                  <Input
                    type="text"
                    name="profesion"
                    id="profesion"
                    disabled={loading}
                    value={profesion}
                    onChange={this.handleChange}
                  />
                  </FormGroup>

                  <FormGroup>
                    <Label for="institucion1">Institución 1 *</Label>
                    <Input
                      type="text"
                      name="institucion1"
                      id="institucion1"
                      disabled={loading}
                      value={institucion1}
                      onChange={this.handleChange}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="institucion2">Institución 2 (facultativo)</Label>
                    <Input
                      type="text"
                      name="institucion2"
                      id="institucion2"
                      disabled={loading}
                      value={institucion2}
                      onChange={this.handleChange}
                    />
                  </FormGroup>

                  {/*<FormGroup>*/}
                    {/*<Label for="password">Password</Label>*/}
                    {/*<Input*/}
                      {/*type="password"*/}
                      {/*name="password"*/}
                      {/*id="password"*/}
                      {/*placeholder="••••••••"*/}
                      {/*disabled={loading}*/}
                      {/*value={password}*/}
                      {/*onChange={this.handleChange}*/}
                    {/*/>*/}
                  {/*</FormGroup>*/}
                  {/*<FormGroup>*/}
                    {/*<Label for="password2">Confirm Password</Label>*/}
                    {/*<Input*/}
                      {/*type="password"*/}
                      {/*name="password2"*/}
                      {/*id="password2"*/}
                      {/*placeholder="••••••••"*/}
                      {/*disabled={loading}*/}
                      {/*value={password2}*/}
                      {/*onChange={this.handleChange}*/}
                    {/*/>*/}
                  {/*</FormGroup>*/}

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={agreeTerms} value="agree" color="primary" onChange={this.handleCheckboxChange}/>
                      }
                      label={agreeLabel}
                    />
                  </FormGroup>

                  <Button color="primary" disabled={loading || !agreeTerms || !this.isAllFieldsFilled()}>
                    {loading ? 'Cargando' : 'Registrarse'}
                  </Button>
                </Form>

                <hr />

                <Row>
                  <Col sm="12">
                    ¿Ya tienes una cuenta?
                    {' '}
                    <Link to="/login">Ingresar</Link>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Dialog open={this.state.modalOpen}
                onClose={this.handleCloseModal}
                aria-labelledby="simple-dialog-title">
          <Button variant="contained"
                  color="primary"
                  size="large"
                  onClick={this.handleCloseModal}
                  style={{position: 'fixed', top: '1px', right: '1px'}}>
            <CloseIcon style={{marginRight: 1}} />
            Cerrar
          </Button>
          <div style={{margin: 15}}>

            {
              this.state.modalView === 'terms' ?
                (<TermsOfService/>) :
                (<Privacy/>)
            }

            {/*<h5>{dialogComunaName}</h5>*/}
            {/*<p style={{fontSize: '14px'}}>Semana Epidemiológica {dialogWeek}</p>*/}
            {/*<p>{"Indice de riesgo: "}*/}
              {/*<strong className={dialogLocationClassName}>*/}
                {/*{dialogIndiceRiestoName}*/}
              {/*</strong>*/}
            {/*</p>*/}
          </div>
        </Dialog>

        {/*<Modal*/}
          {/*aria-labelledby="simple-modal-title"*/}
          {/*aria-describedby="simple-modal-description"*/}
          {/*open={this.state.modalOpen}*/}
          {/*onClose={this.handleCloseModal}*/}
          {/*// disableEnforceFocus*/}
        {/*>*/}
          {/*<div style={getModalStyle()} className={classes.paper}>*/}
            {/*{*/}
              {/*this.state.modalView === 'terms' ?*/}
                {/*(<TermsOfService/>) :*/}
                {/*(<Privacy/>)*/}
            {/*}*/}
          {/*</div>*/}
        {/*</Modal>*/}

      </div>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(SignUp);
