import Store from '../store/default';
import commonConstants from '../constants/commonConstants';

export const initialState = Store;

export default function dataMappingReducer(state = initialState, action) {
  switch (action.type) {
    case 'DATA_MAPPING': {
      // Parsing array to object
      const dataMapping = action.dataMapping;
      // dataMapping.ages = { ...dataMapping.ages };
      // delete dataMapping.ages.0;
      return {
        ...state,
        dataMapping
      };
    }

    default:
      return state;
  }
}
