const comunasRm = {
  "Los_Angeles" : {order: "1", id: "Los_Angeles", name: "Los Ángeles" },
  "Concepcion" : {order: "2", id: "Concepcion", name: "Concepción" },
  "Talcahuano" : {order: "3", id: "Talcahuano", name: "Talcahuano" },
  "Coronel" : {order: "4", id: "Coronel", name: "Coronel" },
  "Lota" : {order: "5", id: "Lota", name: "Lota" },
  "Hualqui" : {order: "6", id: "Hualqui", name: "Hualqui" },
  "San_Pedro_de_la_Paz" : {order: "7", id: "San_Pedro_de_la_Paz", name: "San Pedro de la Paz" },
  "Hualpen" : {order: "8", id: "Hualpen", name: "Hualpen" },
  "Penco" : {order: "9", id: "Penco", name: "Penco" },
  "Tome" : {order: "10", id: "Tome", name: "Tome" },
  "Chiguayante" : {order: "11", id: "Chiguayante", name: "Chiguayante" },
  "Chillan_Mix" : {order: "12", id: "Chillan_Mix", name: "Chillan - Chillan Viejo" },
  "San_Carlos" : {order: "13", id: "San_Carlos", name: "San Carlos" },
};

export default comunasRm;
