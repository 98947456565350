export const firebaseConfig = {
  apiKey: "AIzaSyB8Hh7mU_P2QmhZxGre6BroU8n6gNJis78",
  authDomain: "respira-biobio.firebaseapp.com",
  databaseURL: "https://respira-biobio.firebaseio.com",
  projectId: "respira-biobio",
  storageBucket: "respira-biobio.appspot.com",
  messagingSenderId: "864299495977",
  appId: "1:864299495977:web:e270b95ec433d230bbe238",
  measurementId: "G-1NP2JK3VEV"
};

export const defaultSchema = 'production';
