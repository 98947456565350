import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';



const styles = theme => ({

});

class Map extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <Paper className="paper justifyText" style={{height: '100%'}}>
        <strong>Explicación</strong>
        <p>
          El objetivo de este mapa es exhibir el riesgo de saturación de la red asistencial de salud por un aumento considerable de consultas de urgencias por cuadros respiratorios en las comunas que tienen implementado un sistema de pronóstico.
          </p><p>
          El índice de riesgo considera 5 niveles: bajo, medio, alto, muy alto y extremo.
        </p>
        <p></p>
        <p></p>
        <strong>¿Cómo se calcula el índice de riesgo?</strong>
        <p>
          El índice de riesgo utiliza como base los registros históricos (2012-2020) de consultas de urgencias en la red asistencial de salud pública por comuna. Se considera la información histórica de consultas de urgencias respiratorias para la semana epidemiológica, según la comuna a pronosticar. Estas son divididas en cuartiles donde los niveles ‘bajo’ corresponden a pronósticos de consultas respiratorias bajo la media histórica y los niveles ‘alto’ y ‘muy alto’ corresponden a los pronósticos de demanda de atención por sobre la media histórica de consultas para la semana epidemiológica correspondiente. El valor ‘extremo’ corresponde a un pronóstico de consultas respiratorias mayor al registro histórico más alto de consultas respiratorias para la semana epidemiológica, según la comuna.
        </p>
      </Paper>
    );
  }

}

export default withStyles(styles)(Map);
