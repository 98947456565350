import { Firebase, FirebaseRef } from '../lib/firebase';
import commonConstants from '../constants/commonConstants';

export function getMapData(filters) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  let firebaseUrl = commonConstants.FIREBASE_MAIN_DATA_BASE_URL + "map";
  return dispatch => new Promise(resolve => FirebaseRef.child(firebaseUrl)
    .on('value', (heatMapSnapshot) => {
      FirebaseRef.child('data_mapping/regiones/')
        .once('value')
        .then(regionesSnapshot => {
          const heatMapData = heatMapSnapshot.val() || [];
          const regiones = regionesSnapshot.val() || [];
          return resolve(dispatch({
            type: 'MAP_DATA',
            heatMapData,
            regiones
          }));
        });
    })).catch(e => console.log(e));
}
