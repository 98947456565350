import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import imgMet1 from '../../images/bd-gis.png';
import imgMet2 from '../../images/proc-datos.png';
import imgMet3 from '../../images/metodologia3.png';
import imgMet4 from '../../images/metodologia4.png';
import imgMet5 from '../../images/metodologia5.png';
import arrow from '../../images/arrow3.png';
import logoCams from '../../images/logos/logo-cams.png';
import logoIsp from '../../images/logos/logo-isp.jpg';
import logoDeis from '../../images/logos/logo-deis.jpg';
import logoMedioAmbiente from '../../images/logos/logo-medio-ambiente.jpeg';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Hidden from '../../../node_modules/@material-ui/core/Hidden/Hidden';
import logoAria from "../../images/logos/logo-aria.png";

const styles = theme => ({

});

const About = (props) => {
  const { classes } = props;
  return (
    <div className="section">
      <h1>Metodología</h1>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper className="paper" >
            <h4>Generación de pronósticos semanales</h4>
            <hr/>
            <Grid container spacing={8}
                  direction="row"
                  justify="center">
              <Grid item xs={12} md={4} lg={3}>
                <Paper className="paper" style={{height: '100%'}}>
                  <Grid container
                        direction="column"
                        alignItems="center"
                        justify="space-between" style={{height: '100%'}}>
                    <Grid item/>
                    <Grid item>
                      <img width={210} src={imgMet1} alt="metImg"/>
                    </Grid>
                    <Grid item>
                      <h6 style={{textAlign: 'center'}} >Base de datos</h6>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} md={1}>
                <Grid container spacing={16}
                      direction="row"
                      alignItems="center"
                      justify="center">
                  <Grid item>
                    <Hidden smDown>
                      <img width={70} style={{marginTop: '40px'}} src={arrow} alt="metImg"/>
                    </Hidden>
                    <Hidden mdUp>
                      <KeyboardArrowDown fontSize={'large'} />
                    </Hidden>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Paper className="paper" style={{height: '100%'}}>
                  <Grid container
                        direction="column"
                        alignItems="center"
                        justify="space-between" style={{height: '100%'}}>
                    <Grid item/>
                    <Grid item>
                      <img width={80} src={imgMet2} alt="metImg"/>
                    </Grid>
                    <Grid item>
                      <h6 style={{textAlign: 'center'}} >Uso de modelos estadísticos y modelos autoregresivos</h6>
                    </Grid>
                  </Grid>

                </Paper>
              </Grid>
              <Grid item xs={12} md={1}>
                <Grid container spacing={16}
                      direction="row"
                      alignItems="center"
                      justify="center">
                  <Grid item>
                    <Hidden smDown>
                      <img width={70} style={{marginTop: '40px'}} src={arrow} alt="metImg"/>
                    </Hidden>
                    <Hidden mdUp>
                      <KeyboardArrowDown fontSize={'large'} />
                    </Hidden>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Paper className="paper" style={{height: '100%'}}>
                  <Grid container
                        direction="column"
                        alignItems="center"
                        justify="space-between" style={{height: '100%'}}>
                    <Grid item/>
                    <Grid item>
                      <img width={150} src={imgMet3} alt="metImg"/>
                    </Grid>
                    <Grid item>
                      <h6 style={{textAlign: 'center'}} >Pronóstico automatizado</h6>
                    </Grid>
                  </Grid>

                </Paper>
              </Grid>
            </Grid>

            <br/><br/>
            <h4>Fuentes de información y bases de datos</h4>
            <hr/>

            <Grid container spacing={8}
                  direction="row"
                  justify="center">
              <Grid item xs={12} md={4} lg={3}>
                  <Paper className="paper" style={{height: '100%'}}>
                    <Grid container
                          direction="column"
                          alignItems="center"
                          justify="space-between" style={{height: '100%'}}>
                      <Grid item/>
                      <Grid item>
                        <Grid container spacing={16}
                              direction="row"
                              alignItems="center"
                              justify="center">
                          <Grid item>
                            <a href="http://www.deis.cl/" target="_blank">
                              <img width={70} src={logoDeis} alt="metImg" />
                            </a>
                          </Grid>
                          <Grid item>
                            <a href="http://www.ispch.cl/virusrespiratorios" target="_blank">
                              <img width={70} src={logoIsp} alt="metImg" />
                            </a>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <h6 style={{textAlign: 'center'}} >Consultas de urgencia</h6>
                      </Grid>
                    </Grid>


                  </Paper>
              </Grid>
              <Grid item xs={12} md={1}>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                  <Paper className="paper" style={{height: '100%'}}>
                    <Grid container
                          direction="column"
                          alignItems="center"
                          justify="space-between" style={{height: '100%'}}>
                      <Grid item/>
                      <Grid item style={{marginTop: '10px'}}>
                        <a href="https://www.censo2017.cl/" target="_blank">
                          <img width={150} src={imgMet5} alt="metImg"/>
                        </a>
                      </Grid>
                      <br/>

                      <Grid item>
                        <h6 style={{textAlign: 'center'}} >Datos demográficos</h6>
                      </Grid>
                    </Grid>


                  </Paper>
              </Grid>
              <Grid item xs={12} md={1}>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                  <Paper className="paper" style={{height: '100%'}}>
                    <Grid container
                          direction="column"
                          alignItems="center"
                          justify="space-between" style={{height: '100%'}}>
                      <Grid item/>
                      <Grid item>
                        <Grid container spacing={16}
                              direction="row"
                              alignItems="center"
                              justify="center">
                          <Grid item>
                            <a href="https://sinca.mma.gob.cl/" target="_blank">
                              <img width={70} src={logoMedioAmbiente} alt="logoMedioAmbiente" />
                            </a>
                          </Grid>
                          <Grid item>
                            <a href="https://sinca.mma.gob.cl/" target="_blank">
                              <img width={70} src={logoAria} alt="logoMedioAmbiente" />
                            </a>
                          </Grid>
                          <Grid item>
                            <a href="http://152.74.17.75/conaire/" target="_blank">
                              <Paper style={{padding: '15px 10px 1px 10px', color:'black'}}>
                                <p>CONAIRE 2.0</p>
                              </Paper>
                            </a>
                            <a href="#" target="_blank">

                              {/*<img width={70} src={logoCams} alt="logoCams" />*/}
                            </a>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item style={{marginTop: '20px'}}>
                        <h6 style={{textAlign: 'center'}} >Datos atmosféricos</h6>
                      </Grid>
                    </Grid>
                  </Paper>
              </Grid>
            </Grid>

            <br/><br/>

            <h4>Poder explicativo del modelo</h4>
            <hr/>
            <p className="justifyText">
              De acuerdo a las predicciones fuera de muestra el modelo tiene un poder explicativo de 80%.
            </p>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(About);
